
import { Component, Mixins } from 'vue-property-decorator';
import MarketsCalendarLabel from '@/modules/markets/components/calendar/markets-calendar-label.vue';
import MarketsActions from '@/modules/markets/components/markets-actions.vue';
import MarketsTable from '@/modules/markets/components/markets-table.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import MarketsHeader from '@/modules/markets/components/markets-header.vue';
import MarketsOtelMixin from '@/modules/open-telemetry/mixins/markets/markets-page-otel-logs.mixin';

@Component({
    components: {
        MarketsCalendarLabel,
        MarketsActions,
        MarketsTable,
        PageWrapper,
        MarketsHeader,
    },
})
export default class MarketsTablePage extends Mixins(MarketsOtelMixin) {}
